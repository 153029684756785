import React from "react"
import { Link } from "gatsby"
import '../styles/utilitary.css'
import '../styles/corvina.css'
import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/logo.png" // Tell webpack this JS file uses this image
import gravlaxcover from "../images/Blinis.jpg" // Tell webpack this JS file uses this image
import bacicones from "../images/bac-icones.png" // Tell webpack this JS file uses this image
import bacicones2 from "../images/bac-icones2.png" // Tell webpack this JS file uses this image
import logonew from "../images/logo-new-bac.png" // Tell webpack this JS file uses this image
import diff1 from "../images/diff-1.png" // Tell webpack this JS file uses this image
import diff2 from "../images/diff-2.png" // Tell webpack this JS file uses this image
import diff3 from "../images/diff-3.png" // Tell webpack this JS file uses this image
import price1 from "../images/price-1.png" // Tell webpack this JS file uses this image
import price2 from "../images/price-2.png" // Tell webpack this JS file uses this image
import price3 from "../images/price-3.png" // Tell webpack this JS file uses this image
import time from "../images/time.png" // Tell webpack this JS file uses this image
import pessoas from "../images/pessoas.png" // Tell webpack this JS file uses this image
import ingrediente from "../images/ingrediente.svg" // Tell webpack this JS file uses this image
import StopMotionGravlax from "../images/StopMotionBacalhauaBraz.mp4"
import StopMotionCorvina from "../images/StopMotionCorvina.mp4"


const Corvina = () => (
<Layout>
   <SEO title="Peixe Fácil - Corvina" />
   <div className="wrapper-bg-corvina">
      <div className="center-col-bg">
      <div className="logo-brand">
            <img src={logonew} alt="" />
         </div>
      </div>
   </div>
   <div id="section-1-corvina">
      <div className="wrapper-container">
        
         <div className="titulo absara blue-marine">
            <p>Corvina</p>
         </div>
         <div className="descricao sneak-regular opacity">
            <p> Saboroso, rápido e fácil de preparar!</p>
         </div>
      </div>
   </div>
   <div id="recipes-section-corvina">
      <div className="wrapper-section">
        <div className="col-1">
       
        </div>
        <div className="col-2">
        <div className="wrapper">
           <div className="card-recipe">
              
              <div className="top">
              <p className="title absara"> 
                Corvina no forno com crosta de ervas e legumes
                 </p>
                 
              </div>
              <div className="middle">
              <div className="col1">
                <span className="absara"> 2 Pessoas</span><br></br>
              <img src={pessoas} alt="" />

                
                </div>
                <div className="col2">
                <span className="absara"> 40 Minutos</span><br></br>
                <img src={time} alt="" />

                  </div>
                  <div className="col3">
                  <span className="absara"> Dificuldade</span><br></br>
                  <img src={diff2} alt="" />

                  </div>
                  <div className="col4">
                  <span className="absara"> Preço</span><br></br>
                  <img src={price2} alt="" />

                  </div>
              </div>
              <div className="bottom">
                  <div className="left-col">
                     <div className="card-ingedientes">
                      <span className="titulo absara">Ingredientes</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">2 Lombos de Corvina</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1/2 Limão</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Dente de alho</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Curgete</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">2 Batatas</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">200 g de Abóbora</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Beringela</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Cebola Roxa</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Salsa Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">50 g de Pão Ralado</span><br></br>

                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">4 colheres de sopa de Azeite</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Sal Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Pimenta Q/B</span><br></br>

                     </div>
                  </div>
                  <div className="right-col">
                       <div className="card-receita">
                       <span className="titulo absara">Receita</span><br></br>
                       <span className="span-icon">1 -</span><span className="span-text">Pré-aqueça o forno a 180ºC</span><br></br>
                       <span className="span-icon">2 -</span><span className="span-text">Coloque os lombos de corvina num tabuleiro e tempere-as com sal pimenta e umas gotas de sumo de limão</span><br></br>
                       <span className="span-icon">3 -</span><span className="span-text">Triture a salsa, os coentros, o dente de alho e duas colheres de azeite, até obter uma pasta. Coloque a pasta sobre o peixe e reserve</span><br></br>
                       <span className="span-icon">4 -</span><span className="span-text">Lave e corte os vegetais e de seguida espalhe-os pelo tabuleiro</span><br></br>
                       <span className="span-icon">5 -</span><span className="span-text">Tempere os vegetais com duas colheres de azeite, sal e pimenta</span><br></br>
                       <span className="span-icon">6 -</span><span className="span-text">Leve ao forno durante 20 minutos</span><br></br>



                     </div>
                     </div>
                 </div>
                 <div className="section-nutri">
                  <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                  
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1-nutri">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2-nutri">
                    <div className="titulo sneak-regular">
                            322
                        </div>
                        <div className="titulo sneak-regular">
                            7,8
                        </div>
                        <div className="titulo sneak-regular">
                           1,2
                        </div>
                        <div className="titulo sneak-regular">
                      31
                        </div>
                        <div className="titulo sneak-regular">
                         5
                        </div>
                        <div className="titulo sneak-regular">
                          30
                        </div>
                        <div className="titulo sneak-regular">
                         5
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>

           </div>
        </div>
        </div>
      </div>

    

      <div className="wrapper-section-3">
      <div className="col-1">
      <video className="video-class" preload='auto' controls autoPlay loop playsInline muted>
            <source src={StopMotionCorvina} type="video/mp4" />
          </video>

          
 </div>
        <div className="col-2">
        <div className="wrapper">
           <div className="card-recipe">
              
              <div className="top">
              <p className="title absara"> 
                 Cubos de corvina salteados com massa penne, espargos e tomate cherry
              </p>
              </div>
              <div className="middle">
              <div className="col1">
                <span className="absara"> 2 Pessoas</span><br></br>
              <img src={pessoas} alt="" />

                
                </div>
                <div className="col2">
                <span className="absara"> 40 Minutos</span><br></br>
                <img src={time} alt="" />

                  </div>
                  <div className="col3">
                  <span className="absara"> Dificuldade</span><br></br>
                  <img src={diff2} alt="" />

                  </div>
                  <div className="col4">
                  <span className="absara"> Preço</span><br></br>
                  <img src={price2} alt="" />

                  </div>
              </div>
              <div className="bottom">
                  <div className="left-col">
                     <div className="card-ingedientes">
                        <span className="titulo absara">Ingredientes</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Emb. de Corvina</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">250 g de massa penne</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">200 g de tomate cherry </span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Azeite</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">2 dentes de alho</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Limão</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 molhe de Espargos</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Cebolinho Q/B</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Sal e pimenta Q/B</span><br></br>
  




                     </div>
                  </div>
                  <div className="right-col">
                       <div className="card-receita">
                       <span className="titulo absara">Receita</span><br></br>
                       <span className="span-icon">1 -</span><span className="span-text">Corte os lombos de corvina em pequenos cubos. Tempere-os com sal, pimenta, allho picado e sumo de limão. Deixe marinar durante 15 minutos.</span><br></br>
                       <span className="span-icon">2 -</span><span className="span-text">Coza a massa penne em água com sal. Retire do lume, escorra e reserve.</span><br></br>
                       <span className="span-icon">3 -</span><span className="span-text">Lave os tomates cherry e corte-os em quartos.</span><br></br>
                       <span className="span-icon">4 -</span><span className="span-text">Coza os espargos, escorra-os, passe por água fria e corte em pedaços.</span><br></br>
                       <span className="span-icon">5 -</span><span className="span-text">Numa frigideira anti-aderente coloque um fio de azeite e salteie os espargos, o tomate e a corvina até obter um ligeiro tom dourado.</span><br></br>
                       <span className="span-icon">6 -</span><span className="span-text">Disponha a massa no centro do prato e coloque por cima a corvina, o tomate cherry e os espargos.</span><br></br>
                       <span className="span-icon">7 -</span><span className="span-text">Decore com cebolinho a gosto</span><br></br>


                     </div>
                     </div>
                 </div>

                 <div className="section-nutri">
                  <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                  
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1-nutri">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2-nutri">
                    <div className="titulo sneak-regular">
                            523
                        </div>
                        <div className="titulo sneak-regular">
                            5,6
                        </div>
                        <div className="titulo sneak-regular">
                           1,1
                        </div>
                        <div className="titulo sneak-regular">
                     76
                        </div>
                        <div className="titulo sneak-regular">
                        8,2
                        </div>
                        <div className="titulo sneak-regular">
                          39
                        </div>
                        <div className="titulo sneak-regular">
                         7,4
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>
           </div>
        </div>
        </div>
       
      </div>
      <div className="wrapper-section-2">
        <div className="col-1-mobile">

        </div>
        <div className="col-1">
        <div className="wrapper">
           <div className="card-recipe">
              
              <div className="top">
              <p className="title absara"> 
                 Bruschetta de corvina com endro e ricotta
                 </p>
              </div>
              <div className="middle">
              <div className="col1">
                <span className="absara"> 2 Pessoas</span><br></br>
              <img src={pessoas} alt="" />

                
                </div>
                <div className="col2">
                <span className="absara"> 30 Minutos</span><br></br>
                <img src={time} alt="" />

                  </div>
                  <div className="col3">
                  <span className="absara"> Dificuldade</span><br></br>
                  <img src={diff1} alt="" />

                  </div>
                  <div className="col4">
                  <span className="absara"> Preço</span><br></br>
                  <img src={price1} alt="" />

                  </div>
              </div>
              <div className="bottom">
                  <div className="left-col">
                     <div className="card-ingedientes">
                        <span className="titulo absara">Ingredientes</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">2 postas de Corvina</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">2 Fatias de Pão</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">100 g de Ricotta</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 colher de sopa de Endro</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 colher de chá de Alho em Pó</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1⁄2 limão</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">1 Lima</span><br></br>
                     <span className="span-icon"><img src={ingrediente} alt="Logo" /> </span><span className="span-text">Sal e pimenta Q/B</span><br></br>


                     </div>
                  </div>
                  <div className="right-col">
                       <div className="card-receita">
                       <span className="titulo absara">Receita</span><br></br>
                       <span className="span-icon">1 -</span><span className="span-text">Coza as postas de corvina com um pouco de sal</span><br></br>
                       <span className="span-icon">2 -</span><span className="span-text">Numa taça misture a ricota com o endro o alho em pó e o sumo de limão. Tempere com sal e pimenta</span><br></br>
                       <span className="span-icon">3 -</span><span className="span-text">Separe a corvina em lascas</span><br></br>
                       <span className="span-icon">4 -</span><span className="span-text">Barre o pão com a mistura de ricota e coloque as lascas de corvina por cima</span><br></br>
                       <span className="span-icon">5 -</span><span className="span-text">Sirva com endro e raspas de lima</span><br></br>



                     </div>
                     </div>
                 </div>
                 <div className="section-nutri">
                  <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                  
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1-nutri">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2-nutri">
                    <div className="titulo sneak-regular">
                            513
                        </div>
                        <div className="titulo sneak-regular">
                            14
                        </div>
                        <div className="titulo sneak-regular">
                           6,5
                        </div>
                        <div className="titulo sneak-regular">
                      60
                        </div>
                        <div className="titulo sneak-regular">
                         5,7
                        </div>
                        <div className="titulo sneak-regular">
                          35
                        </div>
                        <div className="titulo sneak-regular">
                         5,7
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>
           </div>
        </div>
        </div>
        <div className="col-2">
 
        </div>
      </div>
   </div>
   <div className="wrapper-3-corvina">
   <div className="logo-footer">
      <div className="logo-brand">
            <img src={logonew} alt="" />
         </div>

      </div>
      <div className="btn-div">
         <Link to="/" style={{ textDecoration: 'none' }}> <button className="btn sneak-bold">Descubra outros produtos!</button></Link>
      </div>
   </div>


</Layout>
)
export default Corvina